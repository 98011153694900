import * as React from "react"
import '../../../gatsby-browser';
import AboutImg from '../../static/img/Home/About.svg'

class About extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){

        let data = this.props.val;


        return(
            <>

                <section id="about">
                    
                        <header className="section-header">
                            <h3>{data?.['about']?.[0].slice(0,5)} <span className="highlighted-text">{data?.['about']?.[0].slice(6,8)}</span></h3>
                            <p>
                                {data?.['about']?.[1]}
                            </p>
                        </header>
                        <div className="row about-extra">
                            <div className="about-img col-lg-6 wow fadeInUp">
                                <img src={AboutImg} className="img-fluid" alt="" width="500" />
                            </div>
                            <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                                <h4 className="highlighted-text">{data?.['info']?.[0]}</h4>
                                <p className="about-description"> 
                                    {data?.["info"]?.[1]}
                                </p>
                            </div>
                        </div>
                   
                </section>

            </>
        )
    }
}

export default About