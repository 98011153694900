import * as React from "react";
import "../../../gatsby-browser";
// import Abouts from "../../static/img/about-img.svg";
// import Team1 from "../../static/img/team-1.jpg";
// import Male from "../../static/img/img/male.png";
// import Female from "../../static/img/img/female.png";
// import KonnectorxIcon from "../../static/img/icons/konnectorx-icon.png";
// import { get } from "jquery";
import { url } from "../../url/url";
import axios from "axios";
import Quotes from '../../static/img/quotes.svg';

class Reviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      reviews: [],
    };
  }

  componentDidMount() {
    axios({ method: "GET", url:`${url}/review/` })
      .then((response) =>this.setState({ reviews: response.data.data }))
      .catch((error) => console.error("Error fetching reviews:", error));
  }

  courseRoute = (name) =>{
      switch (name){
          case "django" : return "/djangodevelopercourse/";
          case "python" : return "/pythondevelopercourse/";
          case "react" : return "/reactdevelopercourse/";
          case "jlpt n4" : return "/japaneseclasses";
          case "jlpt n5" : return "/japaneseclasses";
      }

  }
  render() {
    const reviewData = this.state.reviews;
    //======================== Shuffle Review Data ========================//
    const shuffleArray = (array) =>  {
      const shuffleReviewData = [...array]; // Create a copy of the original array
      for (let i = shuffleReviewData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffleReviewData[i], shuffleReviewData[j]] = [shuffleReviewData[j], shuffleReviewData[i]];
      }
      return shuffleReviewData;
    }
    
  
    const shuffleReviewData = shuffleArray(reviewData);
 
    // ====================================================================//
    // console.log("review",reviewData)
    return (
      <>
        <div className="section-header">
          <h3 className="highlighted-text">
             Testimonials
          </h3>
        </div>
        <section id="team" className="review-container section-bg">
          <div className="scroll-team">
            <button
              onClick={() =>
                document.querySelectorAll(".scroll-row")[1].scrollBy(-350, 0)
              }
              className="left-btn-team"
            >
              <div className="rounded-div">
                <svg
                  fill="#000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns-xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 330 330"
                  xml-space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      id="XMLID_92_"
                      d="M111.213,165.004L250.607,25.607c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.858-15.355-5.858-21.213,0.001 l-150,150.004C76.58,157.211,75,161.026,75,165.004c0,3.979,1.581,7.794,4.394,10.607l150,149.996 C232.322,328.536,236.161,330,240,330s7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L111.213,165.004z"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            </button>
            <div className="scroll-row">
              {shuffleReviewData?.slice(0,10)?.map((item) => {
                return (
                  <div className="review-box">
              
                    <div className="name-container">

                    <span className="student">{(item.course.toUpperCase())} STUDENT</span>
                    <p className="reviewer-name"><span className="name-logo">{item?.username?.slice(0,2)}</span>{item?.username}</p>
                    </div>
                    <img src={Quotes} alt="" />
                    <p className="comment">{item?.comment}</p>
                    <div className="rating">
                    {[...Array(item.rating)].map(()=> (<span>⭐</span>))}
               </div>
                    <a  className="btn btn-more" href={this.courseRoute(item?.course)} target="_blank">View Course</a>
                  </div>
                );
              })}
            </div>
            <button
              onClick={() =>
                document.querySelectorAll(".scroll-row")[1].scrollBy(350, 0)
              }
              className="right-btn-team"
            >
              <div className="rounded-div">
                <svg
                  fill="#000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns-xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 330.002 330.002"
                  xml-space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      id="XMLID_103_"
                      d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21 l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001 c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </section>
      </>
    );
  }
}

export default Reviews;
