import React from "react";
import SuccessStoriesData from "../../lan/StoriesData";

const SuccesStories = () => {
  return (
    <>
      <div className="section-header">
        <h3 className="highlighted-text">Triumph <span className="light-text">Tales</span> </h3>
      </div>
      <section id="team" className="review-container section-bg">
        <div className="scroll-team">
          <button
            onClick={() =>
              document.querySelectorAll(".scroll-row")[2].scrollBy(-350, 0)
            }
            className="left-btn-team"
          >
            <div className="rounded-div">
              <svg
                fill="#000000"
                height="200px"
                width="200px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns-xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330 330"
                xml-space="preserve"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    id="XMLID_92_"
                    d="M111.213,165.004L250.607,25.607c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.858-15.355-5.858-21.213,0.001 l-150,150.004C76.58,157.211,75,161.026,75,165.004c0,3.979,1.581,7.794,4.394,10.607l150,149.996 C232.322,328.536,236.161,330,240,330s7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L111.213,165.004z"
                  ></path>{" "}
                </g>
              </svg>
            </div>
          </button>
          <div className="scroll-row">
            {SuccessStoriesData?.map((data) => {
              return (
                <div className="review-box">
                  <div className="name-container">
                    <span className="student">Part of our {data.course}</span>
                    <p className="reviewer-name">
                      <span className="name-logo">
                        {data.name?.slice(0, 2)}
                      </span>
                      {data.name}
                    </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                  </svg>
                  <p className="comment">{data.comment}</p>
                  <p className="highlighted-text text-img">Placed at: <img src={data.placedAt} alt="logo" className="stories-logo"  /></p>
                </div>
              );
            })}
          </div>
          <button
            onClick={() =>
              document.querySelectorAll(".scroll-row")[2].scrollBy(350, 0)
            }
            className="right-btn-team"
          >
            <div className="rounded-div">
              <svg
                fill="#000000"
                height="200px"
                width="200px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns-xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330.002 330.002"
                xml-space="preserve"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    id="XMLID_103_"
                    d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21 l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001 c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"
                  ></path>{" "}
                </g>
              </svg>
            </div>
          </button>
        </div>
      </section>
    </>
  );
};

export default SuccesStories;
