import * as React from "react";
import "../../../gatsby-browser";
import KonnectorxIcon from "../../static/img/icons/konnectorx-icon.png";
import TeamData from "../../lan/TeamData";

class Team extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <>
        <div className="section-header">
          <h3 className="highlighted-text">
            Our <span className="light-text">Team</span>
          </h3>
        </div>
        <section id="team">
          <div className="scroll-team">
            <button
              onClick={() =>
                document.querySelector(".scroll-row").scrollBy(-350, 0)
              }
              className="left-btn-team"
            >
              <div className="rounded-div">
                <svg
                  fill="#000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns-xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 330 330"
                  xml-space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      id="XMLID_92_"
                      d="M111.213,165.004L250.607,25.607c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.858-15.355-5.858-21.213,0.001 l-150,150.004C76.58,157.211,75,161.026,75,165.004c0,3.979,1.581,7.794,4.394,10.607l150,149.996 C232.322,328.536,236.161,330,240,330s7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L111.213,165.004z"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            </button>
            <div className="scroll-row">
              {TeamData?.map((member) => {
                return (
                  <div className="team-item wow fadeInUp">
                    <div className="member">
                      <img
                        src={member.image}
                        className="img-fluid"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = member.gender;
                        }}
                        alt=""
                      />
                      <div className="member-info">
                        <div className="member-info-content" id={!member.linkedin&& "no-social"}>
                          <h4>{member.name}</h4>
                          <span>{member.designation}</span>
                          <div className="social">
                          { member.linkedin && (<a href={member.linkedin} target="_blank">
                              <i className="fa fa-linkedin"></i>
                            </a>)}
                            {member.konnectorx && (
                              <a href={member.konnectorx} target="_blank">
                                <img
                                  src={KonnectorxIcon}
                                  className="social-logo"
                                  alt=""
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              onClick={() =>
                document.querySelector(".scroll-row").scrollBy(350, 0)
              }
              className="right-btn-team"
            >
              <div className="rounded-div">
                <svg
                  fill="#000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns-xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 330.002 330.002"
                  xml-space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      id="XMLID_103_"
                      d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21 l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001 c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </section>
      </>
    );
  }
}

export default Team;
