import React from 'react';
import {Link} from "gatsby";
// import IllustrationBanner from "../../static/img/Home/IllustrationBanner.svg"
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoPlaySlider = withAutoplay(AwesomeSlider)
const Carousels = ({banner}) => {

  return (
    <>

    <AutoPlaySlider className='carousel'play={true}
    animation="cube-animation"
    infinite={true}
    cancelOnInteraction={false}
    interval={5000}
    >
    {  
  banner?.map((item)=>{
    return ( 
   
    <div data-src={item.bannerPath} >
         <div className="right-container">
       
              <div className="content-container">

              <h2 >{item.heading}</h2>

              {item.bannerContent?.map((data)=>(<p>{data}</p>))}
            <Link align="center" to={'/contactus/'}><button align="center" >Contact Us</button></Link>
              </div>
            

        
        {/* <div className="illustartion"> */}
          {/* <img src={IllustrationBanner} alt="" /> */}
        {/* </div> */}
        </div>
    </div>
    )
  })
 }
    </AutoPlaySlider>
</>
)
}

export default Carousels