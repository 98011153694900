import * as React from "react"
import '../../../gatsby-browser';

// import Abouts from '../../static/img/about-img.svg'
import Client_1 from '../../static/img/clients/cli2.png'
import Client_2 from '../../static/img/clients/housmaid.jpg';
import Client_3 from '../../static/img/clients/c2.png';
import Client_4 from '../../static/img/clients/c4.png';
import Client_5 from '../../static/img/clients/c5.jpg';
import Client_6 from "../../static/img/clients/client0.png"


class Clients extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){
        return(
            <>
                        <div className="section-header">
                            <h3 className="highlighted-text">Our <span className="light-text"> Clients</span></h3>
                        </div>

                <section id="clients" className="section-bg">

                        <div className="row no-gutters clients-wrap clearfix wow fadeInUp">

                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <br />
                                <div className="client-logo" style={{ backgroundColor: 'black' }}>
                                    <a href="https://newzpad.com/" target="_blank">
                                    <img src={Client_1} className="img-fluid" alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <br />
                                <div className="client-logo">
                                    <a href="https://housmaid.com/" target="_blank" >
                                    <img src={Client_2} className="img-fluid" alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <br />
                                <div className="client-logo">
                                    <img src={Client_3} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <br />
                                <div className="client-logo">
                                    <img src={Client_4} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <br />
                                <div className="client-logo">
                                    <img src={Client_5} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <br />
                                <div className="client-logo">
                                    <a href="https://konnectorx.com/" target="_blank">
                                    <img src={Client_6} className="img-fluid" alt=""/>
                                    </a>
                                </div>
                            </div>

                        </div>

                   

                </section>

            </>
        )
    }
}

export default Clients