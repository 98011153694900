import {
  Entracker,
  Delivery,
  Craft,
  JapanM,
  HouseMaid,
} from "../static/img/stories";

const SuccessStoriesData = [
  {
    id: 1,
    name: "Divanshu Kumar(India)",
    course: "programming and language course",
    placedAt: Entracker,
    comment:
      "Thanks to Koderbox's course, I've landed an exciting job in programming! Their training paved the way for my career success.",
  },
  {
    id: 2,
    name: "Kunal(India)",
    course: "programming and language course",
    placedAt: HouseMaid,
    comment:
      "I can't thank Koderbox enough for their course! It not only enhanced my skills but also led to a job offer. The course truly delivers on its promise!",
  },
  {
    id: 3,
    name: "Jitendra Singh(Japan)",
    course: "programming and language course",
    placedAt: JapanM,
    comment:
      "I joined Koderbox's course with high hopes, and it didn't disappoint. I've not only learned immensely but also secured a job that aligns perfectly with my skills.",
  },
  {
    id: 4,
    name: "Ashish Varvdhana",
    course: "programming and language course",
    placedAt: Craft,
    comment:
      "Koderbox's course not only equipped me with valuable skills but also helped me secure a fantastic job. Highly grateful for this career opportunity!",
  },
  {
    id: 5,
    name: "Rahul Gupta",
    course: "programming and language course",
    placedAt: Delivery,
    comment:
      "Koderbox's course made me job-ready in no time! I'm thrilled to have landed a fantastic role in programming, and it's all thanks to their exceptional training",
  },
];

export default SuccessStoriesData;
