import * as React from "react"
import '../../../gatsby-browser';
import Process from "../../static/img/Home/Process.svg"
// import Abouts from '../../static/img/about-img.svg'
// import Next from '../../static/img/next.png'

class Proccess extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){
        return(
            <>

                <section id="services" className="processing">
                        <header className="section-header">
                            <h3 className="highlighted-text">Our <span className="light-text">Process </span> </h3>
                            <p>
                                <hr/>
                            </p>
                        </header>

                        <img src={Process} className="process-img" alt="" />

                        {/* <div className="row proces_part">


                            <div className="col-sm-6" align="center">
                                <div className="card process_card process_text">
                                    <div className="card-body card-1">
                                            <span>
                                                Meet & Agree
                                            </span>
                                    </div>
                                </div><br/>
                            </div>


                            <div className="col-sm-6" align="center">
                                <div className="card process_card process_text">
                                    <div className="card-body card-1">
                                            <span>
                                                Idea & Concept
                                            </span>
                                    </div>
                                </div><br/>
                            </div>


                            <div className="col-sm-6" >
                                <div className="card process_card process_text">
                                    <div className="card-body card-1 ">
                                            <span>
                                                Design & Create
                                            </span>
                                    </div>
                                </div>
                                <br/>
                            </div>


                            <div className="col-sm-6" align="center">
                                <div className="card process_card process_text">
                                    <div className="card-body card-1 ">
                                            <span>
                                                Build & Install
                                            </span>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                </section>

            </>
        )
    }
}

export default Proccess