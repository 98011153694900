import * as React from "react";
import Navbar from "../component/Navbar/Navbar";
import Carousels from "../component/Carousel/Carousels";
import Footer from "../component/Footer/Footer";
import About from "../component/Home/About";
import Service from "../component/Home/Service";
// import Count from "../component/Home/Count";
import Advance from "../component/Home/Advance";
import Proccess from "../component/Home/Proccess";
import Team from "../component/Home/Team";
import Clients from "../component/Home/Clients";
import { lan } from "../lan";
import { motion } from "framer-motion";
import Reviews from "../component/Home/Reviews";
import SuccesStories from "../component/Home/SuccesStories";
import MetaTags from "../component/MetaTags";
import Helmet from 'react-helmet';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      lan_list: "",
    };
  }

  componentDidMount() {
    let tempLan = [];
    if (typeof window !== "undefined") {
      tempLan = lan[localStorage.getItem("lan")];
    } else if (typeof window == "undefined") {
      tempLan = lan["en"];
    } else {
      tempLan = lan["en"];
    }
    this.setState({ lan_list: tempLan });
  }

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };
    const { lan_list } = this.state;

    return (
      <>
      {/* <Layout> */}
      <Helmet>
      {/* Google Tag Manager */}
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W3Z3SGK');`}</script>
      {/* End Google Tag Manager */}

      {/* <!-- Google tag (gtag.js) --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11366711178"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-11366711178');

          gtag('event', 'conversion', {
              'send_to': 'AW-11366711178/TKU1CPuf7cIZEIr_iKwq',
              'value': 1.0,
              'currency': 'JPY'
          });

        `}
      </script>
      
    </Helmet>
        <main>
          <Navbar />
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
          >
            <Carousels banner={lan_list?.["banner"]} />
            <main id="main">
              <Service val={lan_list} />
              <About val={lan_list} />
              {/*<Count val={lan_list}/>*/}
              <Advance val={lan_list} />
              <Team />
              <Proccess />
              <Reviews />
              <SuccesStories />
              <Clients />
              <Footer />
            </main>
          </motion.div>
        <MetaTags title={"Home | Koderbox"} description={lan_list.info?.[2]} />
        </main>
        {/* </Layout> */}
      </>
    );
  }
}

export default IndexPage;
