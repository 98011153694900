import * as React from "react"
import '../../../gatsby-browser';
// import Soft from "../../static/img/soft.png";
import {Link} from "gatsby";
import WebIcon from "../../static/img/icons/WebIcon.svg"
import Mobile from "../../static/img/icons/Mobile.svg"
import SearchEngine from "../../static/img/icons/SearchEngine.svg"
import Social from "../../static/img/icons/SocialMarketting.svg"
import Language from "../../static/img/icons/Language.svg"
import Coding from "../../static/img/icons/Coding.svg"

class Service extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){

        let data = this.props.val

        // console.log(data?.['service'][0])

        return(
            <>

                <section id="services" className="services">
                      <header className="section-header">
                            <h3 className="highlighted-text">{data?.['service_head']?.[0]?.slice(0,3)}  <span className="light-text" >{data?.['service_head']?.[0].slice(3,)} </span></h3>
                        </header>

                        <div className="row services-bg">
                        <div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="box-title">
                                      <span className="service_img">
                                         <img src={Language} alt="" className="img-fluid" width="100" />
                                      </span> <br/><br/>
                                    <h4 className="title highlighted-text"> {data?.['service']?.[5]?.[0]} </h4>
                                    </div>
                                    <p className="description">
                                        {data?.['service']?.[5]?.[1]}
                                        <br/><br/>
                                        <Link to={'/japaneseclasses/'}> <button className="btn btn-more"> More </button> </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="box-title">
                                      <span className="service_img">
                                         <img src={Coding} alt="" className="img-fluid" width="100" />
                                      </span> <br/><br/>
                                    <h4 className="title highlighted-text"> {data?.['service']?.[6]?.[0]} </h4>
                                    </div>
                                    <p className="description">
                                        {data?.['service']?.[6]?.[1]}
                                        <br/><br/>
                                        <Link to={'/courses/'}> <button className="btn btn-more"> More </button> </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="box-title">
                                      <span className="service_img">
                                         <img src={WebIcon} alt="" className="img-fluid" width="100" />
                                      </span> <br/><br/>
                                    <h4 className="title highlighted-text"> {data?.['service']?.[0]?.[0]} </h4>
                                    </div>
                                    <p className="description">
                                        {data?.['service']?.[0]?.[1]}
                                        <br/><br/>
                                        <Link to={'/webdevelopment/'}> <button className="btn btn-more"> More </button> </Link>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="box-title">
                                      <span className="service_img">
                                         <img src={Mobile} alt="" className="img-fluid" width="100" />
                                      </span> <br/><br/>
                                    <h4 className="title highlighted-text"> {data?.['service']?.[1]?.[0]} </h4>
                                    </div>
                                    <p className="description">
                                        {data?.['service']?.[1]?.[1]}
                                        <br/><br/>
                                        <Link to={'/mobiledevelopment/'}> <button className="btn btn-more"> More </button> </Link>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="box-title">

                                      <span className="service_img">
                                         <img src={Social} alt="" className="img-fluid" width="100" />
                                      </span> <br/><br/>
                                    <h4 className="title highlighted-text"> {data?.['service']?.[2]?.[0]} </h4>
                                    </div>
                                    <p className="description">
                                        {data?.['service']?.[2]?.[1]}
                                        <br/><br/>
                                        <Link to={'/smo/'}> <button className="btn btn-more"> More </button> </Link>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">
                                <div className="box">
                                    <div className="box-title">
                                      <span className="service_img">
                                         <img src={SearchEngine} alt="" className="img-fluid" width="100" />
                                      </span> <br/><br/>
                                    <h4 className="title highlighted-text"> {data?.['service']?.[3][0]} </h4>
                                    </div>
                                    <p className="description">
                                        {data?.['service']?.[3][1]}
                                        <br/><br/>
                                        <Link to={'/SEO/'}> <button className="btn btn-more"> More </button> </Link>
                                    </p>
                                </div>
                            </div>
                            {/*<div className="col-md-6  wow bounceInUp" data-wow-duration="1.4s">*/}
                            {/*    <div className="box">*/}
                            {/*          <span className="service_img">*/}
                            {/*             <img src={Soft} alt="" className="img-fluid" width="100" />*/}
                            {/*          </span> <br/><br/>*/}
                            {/*        <h4 className="title"> {data?.['service'][4][0]} </h4>*/}
                            {/*        <p className="description">*/}
                            {/*            {data?.['service'][4][1]}*/}
                            {/*            <br/><br/>*/}
                            {/*            <Link to={'/webdevelopment/'}> <button className="btn btn-more"> More </button> </Link>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                </section>

            </>
        )
    }
}

export default Service