import * as React from "react"
import '../../../gatsby-browser';
// import Abouts from '../../static/img/about-img.svg'
// import Soft from "../../static/img/soft.png";
// import Web from "../../static/img/web.png";
// import Mobile from "../../static/img/mobile.png";

class About extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[],
            isViewMore:false
        }
    }

    handleViewMore = () =>{
        this.setState((prev)=>({ ...prev,isViewMore:!prev.isViewMore}))
    }

    render(){

        let data = this.props.val;
        const  {isViewMore}= this.state 

        return(
            <>

                <section id="services" className="advance">
                        <header className="section-header">
                            <h3 className="highlighted-text advance-services">{data?.['approach']?.[0]}</h3>
                            <p>
                                {data?.['approach']?.[1]}
                            </p>
                        </header>

                        <div className="row">

                            {data?.['app_list']?.slice(0, isViewMore ? data?.['app_list']?.length : 4)?.map((item, index) => {
                                // console.log(item.head)
                                return(
                                    <>
                                        <div className="col-md-6 " data-wow-duration="1.4s">
                                            <div className="box">
                                                <h4 className="title">{item?.head}</h4>
                                                <p className="description">
                                                    {item?.value}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                            <button className="btn btn-more btn-view-more" onClick={this.handleViewMore}>{!isViewMore? "View More" : "View Less" }</button>
                </section>

            </>
        )
    }
}

export default About